<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <b>Send Broadcast</b>
          </CCardHeader>
          <CCardBody>
            <CAlert
              :color="alertColor"
              closeButton
              :show.sync="showAlert"
            >
              {{ alertContent }}
            </CAlert>
            <div class="row">
              <div class="col-md-6">
                <div class="card">
                  <article class="card-group-item">
                    <header class="card-header">
                      <b>Broadcast Condition</b>
                    </header>
                    <div class="filter-content">
                      <div class="card-body">
                        <div class="form-group">
                          <label>List ID</label>
                          <v-select
                            multiple
                            :options="availableApps"
                            v-model="selectedApps"
                            @input="onAppIdsChanged"
                          />
                        </div>
                      </div> <!-- card-body.// -->
                    </div>
                  </article> <!-- card-group-item.// -->
                  
                  <article class="card-group-item">
                    <header class="card-header">
                      <b>Information</b>
                      <div
                        class="spinner-border spinner-border-sm login-spinner"
                        role="status"
                        :hidden="!infoLoading"
                        >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <CButton
                        size="sm"
                        color="primary"
                        v-c-tooltip.hover.click="'The cost value bellow is estimated value. Click Show Detail for exact value.'"
                        @click="infoDetail = !infoDetail; getBroadcastSummary();"
                        style="float: right;"
                        >
                        <template v-if="infoDetail">
                          Hide Detail
                        </template>
                        <template v-else>
                          Show Detail
                        </template>
                      </CButton>
                    </header>
                    <div class="filter-content">
                      <div class="card-body">
                        <p>
                          This broadcast message will be sent to <b>{{ infoDetailData.total_users }}</b> users.
                          Each user will receive <b>{{ numberOfSms }}</b> sms.
                        </p>
                        <p>The total cost will be <b>{{ totalPrice }}$.</b>
                          <template v-if="infoDetail">
                            (Nearly exact value).
                          </template>
                          <template v-else>
                            (Estimated value).
                          </template>
                        </p>
                        <CCollapse :show="infoDetail" :duration="400">
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Country</th>
                                <th scope="col">Total Users</th>
                                <th scope="col">Unit Prices</th>
                                <th scope="col">Total Cost</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, name) in infoDetailData.details" :key="name">
                                <td>{{ name }}</td>
                                <td>{{ item.total_users }}</td>
                                <td>{{ item.unit_price }}</td>
                                <td>{{ Math.round(item.total_users * item.unit_price * 100) / 100 }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </CCollapse>
                      </div> <!-- card-body.// -->
                    </div>
                  </article> <!-- card-group-item.// -->
                </div> <!-- card.// -->
              </div>
              <div class="col-md-6">
                <CCard>
                  <CCardHeader>
                    <b>Broadcast Content</b>
                  </CCardHeader>
                  <CCardBody>
                    <CForm style="margin-top: 24px;">
                      <CTextarea
                        label="Message"
                        rows="8"
                        horizontal
                        v-model="bcMessage"
                        :description="msgDescription"
                      />
                      <CButton
                        color="primary"
                        style="float: right;"
                        @click="validateForm"
                        >
                        Send Broadcast
                      </CButton>
                    </CForm>
                  </CCardBody>
                </CCard>
              </div>
            </div>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      title="Broadcast Confirmation?"
      :show.sync="confirmSendModal"
      @update:show="onConfirmModalClose"
    >
      <p>Are you sure you want to send this broadcast?</p>
      <p>
        This broadcast message will be sent to <b>{{ infoDetailData.total_users }}</b> users.
        Each user will receive <b>{{ numberOfSms }}</b> sms.
      </p>
      <p>The total cost will be <b>{{ totalPrice }}$.</b>
        <template v-if="infoDetail">
          (Nearly exact value).
        </template>
        <template v-else>
          (Estimated value).
        </template>
      </p>
    </CModal>
  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import AxiosBase from '../../api/AxiosBase'
import AppApi from '../../api/AppApi'
import NumberUtils from '../../common/NumberUtils'

export default {
  name: 'SendBroadcast',
  created() {
    Vue.component('v-select', vSelect);
    this.getApps();
    this.getBroadcastSummary();
    this.getSmsMaxSize();
  },
  data () {
    return {
      alertColor: 'success',
      showAlert: false,
      alertContent: "",

      availableApps: [],

      selectedApps: [],
      bcMessage: "",

      infoDetail: false,
      infoLoading: false,
      infoDetailData: {},

      smsMaxSize: 0,

      confirmSendModal: false,

    }
  },
  computed: {
    msgDescription: function() {
      return `This message contains ${this.bcMessage.length} characters. The maximum of characters for 1 SMS is ${this.smsMaxSize}.`;
    },
    numberOfSms: function() {
      return Math.floor(this.bcMessage.length / (this.smsMaxSize + 1)) + 1;
    },
    totalPrice: function() {
      var totalCost;
      if (this.infoDetail) {
        totalCost = this.infoDetailData.total_cost;
      } else {
        totalCost = this.infoDetailData.total_users * this.infoDetailData.avg_unit_price;
      }
      return NumberUtils.formatDecimal(totalCost, 2);
    }
  },
  methods: {
    getApps() {
      var _this = this;
      AppApi.getApps().then(result => _this.availableApps = result);
    },
    getSmsMaxSize() {
      var _this = this;

      AxiosBase.get("/common/sms/max_size")
      .catch(function(error) {
        console.log(error);
      })
      .then(function(res) {
        var resCode = res?.data?.code;
        var resValue = res?.data?.value;

        if (resCode != null && resCode >= 0) {
          _this.smsMaxSize = resValue;
        }
      });
    },
    getBroadcastSummary() {
      if (this.infoDetail) {
        this._getBroadcastSummaryDetail();
      } else {
        this._getBroadcastSummary();
      }
    },
    _getBroadcastSummary() {
      var _this = this;
      _this.infoLoading = true;

      AxiosBase.post("/broadcast/summary", {
        app_ids: _this.selectedApps
      })
      .catch(function(error) {
        console.log(error);
        _this.infoLoading = false;
      })
      .then(function(res) {
        var resCode = res?.data?.code;
        var resValue = res?.data?.value;

        if (resCode != null && resCode >= 0) {
          _this.infoDetailData = resValue;
        }

        _this.infoLoading = false;
      });
    },
    _getBroadcastSummaryDetail() {
      var _this = this;
      _this.infoLoading = true;

      AxiosBase.post("/broadcast/summary_detail", {
        app_ids: _this.selectedApps
      })
      .catch(function(error) {
        console.log(error);
        _this.infoLoading = false;
      })
      .then(function(res) {
        var resCode = res?.data?.code;
        var resValue = res?.data?.value;

        if (resCode != null && resCode >= 0) {
          _this.infoDetailData = resValue;
        }

        _this.infoLoading = false;
      });
    },
    validateForm() {
      var _this = this;

      if (!_this.bcMessage) {
        _this.showAlert = true;
        _this.alertColor = "danger";
        _this.alertContent = "Message is required.";
        return;
      }

      if (parseInt(_this.totalUsers) <= 0) {
        _this.showAlert = true;
        _this.alertColor = "danger";
        _this.alertContent = "The user list is empty.";
        return;
      }

      _this.confirmSendModal = true;
    },
    sendBroadcast() {
      var _this = this;

      AxiosBase.post("/broadcast/send", {
        user_id: localStorage.getItem('username'),
        app_ids: _this.selectedApps,
        message: _this.bcMessage
      })
      .catch(function(error) {
        console.log(error);
      })
      .then(function(res) {
        var resCode = res?.data?.code;
        var resValue = res?.data?.value;

        if (resCode != null && resCode >= 0) {
          _this.showAlert = true;
          _this.alertColor = "success";
          _this.alertContent = "Broadcast has been queued.";

          _this.bcMessage = "";
          _this.selectedApps = [];
          _this.getBroadcastSummary();
        } else {
          _this.showAlert = true;
          _this.alertColor = "danger";
          _this.alertContent = `There is an error from server. Code: ${resCode}`
        }
      });
    },
    onAppIdsChanged(value) {
      console.log(this.selectedApps);
      this.getBroadcastSummary();
    },
    onConfirmModalClose(status, evt, accept) {
      if (accept) {
        this.sendBroadcast();
      }
    }
  }
}
</script>
