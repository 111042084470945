import AxiosBase from "./AxiosBase"

export default {
  getApps: function() {
    var availableApps = [];

    return AxiosBase.get("/app/list", {
      params: {}
    })
    .catch(function(error) {
      console.log(error);
      return availableApps;
    })
    .then(function(res) {
      var resCode = res?.data?.code;
      var resApps = res?.data?.value;

      if (resCode != null && resCode >= 0 && resApps) {
        for (var item of resApps) {
          availableApps.push(item.name);
        }
      } else {
        // show errors
        console.log(JSON.stringify(res));
      }
      return availableApps;
    });
  }
}